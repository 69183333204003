import $store from '../store/index'
import axios from 'axios'
import * as common from '../api/common'
import * as constant from '../api/constant'
import * as api from '../api/api'

export const isValidPhoneNumber = (phoneNumber) => {
  // 定义中国大陆手机号的正则表达式
  var pattern = /^1\d{10}$/

  // 使用正则表达式测试手机号是否匹配
  return pattern.test(phoneNumber)
}

// 点击菜单或者页面时 判断是否授权登录
export const isHaveAuthAccount = () => {
  const authObj = $store.state.wxZfbAuthInfo ? $store.state.wxZfbAuthInfo.wxZfbAuthInfo : null
  if (!authObj) {
    return false
  }
  // ToDo 是否需要判断授权的有效期。。。
  return true
}
// 点击菜单或者页面时 判断是否绑定手机号
export const isHaveBindPhone = () => {
  const phone = $store.state.phone ? $store.state.phone.phone : null
  if (!phone) {
    return false
  }
  return true
}

export const judgeEndDateLong = (str) => {
  if (str.substring(0, 4) >= constant.endDateFlag) {
    return true
  } else {
    return false
  }
}
export const judgeStartDateLong = (str) => {
  if (str.substring(0, 4) <= constant.startDateFlag) {
    return true
  } else {
    return false
  }
}
// 获取 优惠券名称
export const getCouponName = (type) => {
  let name = ''
  const couponTypeArray = constant.couponTypeArray
  for (let x = 0; x < couponTypeArray.length; x++) {
    if (type === couponTypeArray[x].type) {
      name = couponTypeArray[x].couponName
    }
  }
  return name
}
// 获取 优惠券状态名称
export const getCouponStatusName = (type) => {
  let name = ''
  const couponStatusList = constant.couponStatusList
  for (let x = 0; x < couponStatusList.length; x++) {
    if (type === couponStatusList[x].type) {
      name = couponStatusList[x].typeName
    }
  }
  return name
}
// 根据优惠券名称获取对应的Value
export const getCouponValByname = (para) => {
  let name = ''
  const couponTypeArray = constant.couponTypeArray
  for (let x = 0; x < couponTypeArray.length; x++) {
    if (para === couponTypeArray[x].couponName) {
      name = couponTypeArray[x].type
    }
  }
  return name
}
// 根据优惠券状态名称获取对应的Value
export const getCouponStatusValByname = (para) => {
  let name = ''
  const couponStatusList = constant.couponStatusList
  for (let x = 0; x < couponStatusList.length; x++) {
    if (para === couponStatusList[x].typeName) {
      name = couponStatusList[x].type
    }
  }
  return name
}
// 判断需不需要进行JSON解析
export const parseIfJSONString = (obj) => {
  try {
    // 尝试解析为 JSON 数组
    const parsed = JSON.parse(obj)
    // 如果成功解析，则返回解析后的数组
    if (Array.isArray(parsed)) {
      return parsed
    }
  } catch (error) {
    // 如果解析失败，返回原始值
    return obj
  }
  // 如果不是 JSON 字符串，则返回原始值
  return obj
}

/**
 * 获取广告配置
 */
export const queryADConfig = (sid) => {
  return new Promise(function (resolve, reject) {
    axios({
      url: common.iotUrl + '/s2p/' + 'sid.feature.get_by_sid',
      method: 'POST',
      data: {
        sid: sid,
        version: 'v2'
      },
      header: {
        'content-type': 'application/json' // 默认值
      }
    }).then(res => {
      console.log(res, '' +
        '获取关闭广告配置')
      const d = res && res.data
      if (d.code === 10000) {
        const config = d.info
        resolve(config)
      } else {
        reject(new Error({ code: d.code, msg: d.msg }))
      }
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 判断广告配置是否需要关闭
 * @param sid 车场ID
 * @param getType 是否调用接口获取，1为从接口获取，2为本地缓存获取
 * @param adType 广告类型，1为非支付页面广告；2为支付页面广告，包含首页
 */
export const judgeADClose = (adConfig, getType, adType) => {
  let startTime, endTime
  if (getType === 1) {
    // 首页需要保存
    if (adConfig == null) {
      localStorage.setItem('adConfig', '{}')
    } else {
      localStorage.setItem('adConfig', JSON.stringify(adConfig))
    }
  } else {
    const firstConfig = localStorage.getItem('adConfig')
    if (firstConfig) {
      adConfig = JSON.parse(firstConfig)
    }
    if (!adConfig) {
      adConfig = {}
    }
  }
  if (adType === 1) {
    if (adConfig.ad_other_page && adConfig.ad_other_page.start_time) {
      startTime = adConfig.ad_other_page.start_time
    }
    if (adConfig.ad_other_page && adConfig.ad_other_page.end_time) {
      endTime = adConfig.ad_other_page.end_time
    }
  } else {
    if (adConfig.ad_pay_page && adConfig.ad_pay_page.start_time) {
      startTime = adConfig.ad_pay_page.start_time
    }
    if (adConfig.ad_pay_page && adConfig.ad_pay_page.end_time) {
      endTime = adConfig.ad_pay_page.end_time
    }
  }

  const now = new Date()
  if (!startTime || !endTime) {
    return false
  }
  if (now >= formatDate(startTime) && now <= formatDate(endTime)) {
    return true
  } else {
    return false
  }
}

// 转化指定日期字符串 为 中国标准时间 兼容方式  防止直接new Date（）意外
export const formatDate = function (dateStr) {
  if (!dateStr) {
    return ''
  }

  if (/^\d{2,4}(-|\/)\d{1,2}(-|\/)\d{1,2}/.test(dateStr)) {
    let gmtTime
    if (/^\d{2,4}-\d{1,2}-\d{1,2}/.test(dateStr)) {
      gmtTime = /\.\d+Z$/.test(dateStr)
      dateStr = dateStr.replace(/-/g, '/').replace(/T/, ' ').replace(/\.\d+Z$/, '').replace(/\.\d+\+/, '+')
        .replace(/\+\d{2}:\d{2}$/, '')
    }

    const date = new Date(dateStr)
    if (gmtTime && date) {
      date.setMinutes(date.getMinutes() - new Date().getTimezoneOffset())
    }
    return date
  } else {
    return ''
  }
}

// 根据优惠券类型获取对应的样式class
export const getClassByCouponType = (type) => {
  let name = ''
  if (type === 1 || type === 11) {
    name = 'couponTypeOne'
  } else if (type === 2 || type === 12) {
    name = 'couponTypeTwo'
  } else if (type === 3 || type === 13) {
    return 'couponTypeThree'
  } else {
    name = 'couponTypeFour'
  }
  return name
}
// 根据优惠券类型获取对应的name
export const getNameByCouponType = (type) => {
  let name = ''
  switch (type) {
    case 1:
      name = '金额减免券(券面)'
      break
    case 2:
      name = '时长减免券(券面)'
      break
    case 3:
      name = '折扣减免券(券面)'
      break
    case 4:
      name = '全免券'
      break
    case 11:
      name = '金额减免券'
      break
    case 12:
      name = '时长减免券'
      break
    case 13:
      name = '折扣减免券'
      break
    default:
  }
  return name
}
// 根据优惠券类型获取对应的单位
export const getUnitByCouponType = (type) => {
  let name = ''
  if (type === 1 || type === 11) {
    name = '元'
  } else if (type === 2 || type === 12) {
    name = '分钟'
  } else if (type === 3 || type === 13) {
    return '折'
  } else {
    name = '张'
  }
  return name
}

export const parkTimeformat = (str) => {
  if (!str) return ''
  const timeParts = str.split(':')
  const hours = parseInt(timeParts[0], 10)
  const minutes = parseInt(timeParts[1], 10)
  const seconds = parseInt(timeParts[2], 10)

  return `${hours}时${minutes}分${seconds}秒`
}

export const isTimeGreaterThanZero = (timeString) => {
  if (!timeString) return false
  const parts = timeString.split(':')
  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(parts[2], 10)

  const totalSeconds = (hours * 3600) + (minutes * 60) + seconds
  return totalSeconds > 0
}

export const timeStringToSeconds = (timeString) => {
  const parts = timeString.split(':')

  const hours = parseInt(parts[0], 10)
  const minutes = parseInt(parts[1], 10)
  const seconds = parseInt(parts[2], 10)

  const totalSeconds = (hours * 3600) + (minutes * 60) + seconds

  return totalSeconds
}

// 对象一级的成员字典序排序拼接，二级成员做 JSON.stringify
export const objSortJoin = (obj, connection_symbol = '&') => {
  let str = ''
  if (obj && typeof obj === 'object') {
    const newKey = Object.keys(obj).sort()
    for (let i = 0; i < newKey.length; i++) {
      const key = newKey[i]
      const value = obj[key]
      if (value == undefined) {
        continue
      }
      str += `${connection_symbol}${key}=${typeof obj[key] === 'object' ? JSON.stringify(value) : value}`
    }
    if (str.length) {
      str = str.substr(connection_symbol.length, str.length)
    }
  }
  return str
}
// 获取查询的地址
export const getIndexUrl = () => {
  return getUrlObjBase(window.location) + '/index'
}
export const getUrlObjBase = (urlObj) => {
  let url = urlObj.protocol + '//' +
    (urlObj.hostname || urlObj.host)
  if (urlObj.port) {
    url += ':' + urlObj.port
  }
  url += urlObj.pathname
  if (window.location.hash && window.location.hash.indexOf('#') === 0) {
    url += '#'
  }
  return url
}
// 获取发券后有效期格式化
export const getUsefulTime = (key) => {
  const list = constant.expireLen
  let name = ''
  list.forEach((x) => {
    if (Number(key) === x.value) {
      name = x.label
    }
  })
  return name
}

// 获取车场的支付平台
export const getPayTypeBySid = (sid) => {
  return axios({
    url: common.cloudPayUrl + '/pub/' + api.urlList.pay.get_sid_pay_way,
    method: 'POST',
    data: {
      sid: sid
    },
    headers: {
      'content-type': 'application/json' // 默认值
    }
  }).then(res => {
    const d = res && res.data
    console.log('获取到车场的支付平台init', d)
    if (d.code === 10000) {
      console.log('获取到车场的支付平台', d)
      return d.sid_pay_way.wechat
    } else {
      return Promise.reject(new Error(d.msg || 'Unknown error'))
    }
  }).catch(error => {
    console.error('Error fetching payment type:', error.message || error)
    throw error
  })
}

export const getNextDayStart = (dateString) => {
  // 将日期字符串转换为Date对象
  const date = new Date(dateString)

  // 增加一天（注意JavaScript中月份是从0开始的，但天是从1开始的，所以这里我们加1）
  date.setDate(date.getDate() + 1)

  // 设置时间为00:00:00
  date.setHours(0, 0, 0, 0) // 小时, 分钟, 秒, 毫秒

  // 使用toISOString()并切片到不需要的毫秒部分，或者使用自定义的格式化
  // 注意：toISOString() 返回的是 UTC 时间，如果你需要本地时间，请考虑使用其他方法
  // 例如，使用 Intl.DateTimeFormat 来格式化为本地时间
  // 但如果你只是想要标准的 "YYYY-MM-DD HH:mm:ss" 格式并且不关心时区，以下方式足够
  // 或者你可以简单地去掉最后三个字符（毫秒部分）
  const formattedDate = date.toISOString().slice(0, 19).replace('T', ' ')

  // 如果你想要考虑时区，并且输出本地时间，可以这样做：
  // 注意：这取决于浏览器对Intl.DateTimeFormat的支持
  // const formatter = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
  // const formattedDate = formatter.format(date);

  return formattedDate
}

export const getPayType = (str) => {
  const list = constant.payTypeOptions
  let name = ''
  list.find((x) => {
    if (x.value === str) {
      name = x.label
    }
  })
  return name
}

// 计算指定时间差 得出停车时长(x小时x分钟x秒) "2024-08-28T08:10:50.000Z"  "2024-08-28T08:11:06.000Z"
export const countTimeStartEnd = (startTime, endTime) => {
  if (!startTime || !endTime) return ''
  const start = new Date(startTime)
  const end = new Date(endTime)

  let diff = Math.abs(end - start)

  const hours = Math.floor(diff / (1000 * 60 * 60))
  diff -= hours * (1000 * 60 * 60)

  const minutes = Math.floor(diff / (1000 * 60))
  diff -= minutes * (1000 * 60)

  const seconds = Math.floor(diff / 1000)

  return `${hours}小时${minutes}分钟${seconds}秒`
}
// 根据指定日期获取对应月份的结束日期  "2024-08-28T08:10:50.000Z"
export const countRentTimeShow = (dateStr, monthNum) => {
  const date = new Date(dateStr)

  const originalDay = date.getDate()

  date.setMonth(date.getMonth() + monthNum)

  if (date.getDate() < originalDay) {
    date.setDate(0)
  } else {
    date.setDate(originalDay)
  }

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
export const getNextDay = (dateStr) => {
  const date = new Date(dateStr)

  date.setDate(date.getDate() + 1)

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  return `${year}-${month}-${day}`
}
